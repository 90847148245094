.img {
    background: linear-gradient(rgba(0,0,0,0.2), rgb(0,0,0,0.2)), url("../img/cut.jpg");
    width: 100%;
    padding-bottom: 56.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

.content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.title {
    position: absolute;
    color: white;
    text-align: center;
    bottom: 20px;
    right: 40px;
    width: 600px;
    font-size: 114px;
    font-family: 'Dancing Script', cursive;
}

.date {
    position: relative;
    color: white;
    text-align: center;
    top: 20px;
    left: 40px;
    font-size: 98px;
    font-family: 'Dancing Script', cursive;
}

@media (max-width: 1600px) {
    .title {
        font-size: 100px;
    }

    .date {
        font-size: 86px;
    }
}

@media (max-width: 1300px) {
    .title {
        font-size: 80px;
        width: 400px;
    }

    .date {
        font-size: 66px;
    }
}

@media (max-width: 1150px) {
    .title {
        font-size: 60px;
    }

    .date {
        font-size: 44px;
    }
}

@media (max-width: 1024px) {
    .img {
        background: linear-gradient(rgba(0,0,0,0.2), rgb(0,0,0,0.2)), url("../img/header_small.jpg");
        background-size: cover;
        padding-bottom: 100%;
    }

    .title {
        font-size: 100px;
    }

    .date {
        font-size: 86px;
    }
}

@media (max-width: 900px) {
    .title {
        font-size: 80px;
        width: 300px;
    }

    .date {
        font-size: 66px;
    }
}

@media (max-width: 900px) {
    .title {
        font-size: 60px;
        width: 200px;
    }

    .date {
        font-size: 44px;
    }
}

@media (max-width: 550px) {
    .title {
        font-size: 32px;
        width: auto;
        right: 0px;
        left: 0px;
    }

    .date {
        position: absolute;
        font-size: 30px;
        width: auto;
        right: 0px;
        left: 0px;
        top: 10px;
    }
}