body, html {
  margin: 0;
  width: 100%;
  background-color: ivory;
}

#root {
  margin: 0;
  width: 100%;
}
