.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 100px;
    font-size: 30px;
}

.infoContainer {
    display: flex;
}

.news {
    display: flex;
    flex-direction: column;
    border: 20px dashed darkblue;
    border-radius: 15px;
    padding: 40px;
    width: 600px;
    text-align: center;
    margin-top: 100px;
}

.heading {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 34px;
}

.column {
    display: flex;
    flex-direction: column;
}

.img {
    width: 100%;
    margin-top: 100px;
    background-image: url("../img/rose_schmal.jpg");
    padding-bottom: 18%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.info {
    text-align: center;
    margin-top: 100px;
    width: 1200px;
}

.newsLine {
    margin-bottom: 15px;
}

.arrow {
    font-weight: bolder;
    margin-right: 10px;
    min-width: 25px;
}

.arrowInvisible {
    min-width: 75px;
    margin-right: 10px;
}

.coronaContainer {
    margin: 30px 0;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    text-align: left;
}

.corona {
    margin-top: 20px;
}

.bullet {
    display: flex;
    margin-top: 25px;
    width: 600px;
}

.kuchen {
    overflow-wrap: break-word;
}

.spiele {
    overflow-wrap: break-word;
    color: red
}

.bulletContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    transform: translateX(20px);
}

.accomodations {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
    width: 1200px;
    overflow-wrap: break-word;
}

.ablauf {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(208,236,208);
    padding: 50px;
    width: 100%;
    margin-top: 100px;
    box-sizing: border-box;
}

@media (max-width: 1300px) {
    .accomodations, .info {
        width: 70%;
    }

    .App {
        margin: 0px 50px;
    }
}

@media (max-width: 1024px) {
    .App {
        margin: 0px 0px;
        font-size: 24px;
    }

    .bullet {
        width: 500px;
    }

    .heading {
        font-size: 26px;
    }

    .news, .info, .accomodations, .img, .ablauf {
        margin-top: 50px;
    }

    .bulletContainer {
        margin-bottom: 25px;
    }

    .img {
        background-image: url("../img/blumen_small.jpg");
        padding-bottom: 61.2%;
    }
}

@media (max-width: 900px) {
    .news {
        width: 400px;
        border: 16px dashed darkblue;
        padding: 30px;
    }
}

@media (max-width: 600px) {
    .news {
        width: 240px;
        border: 8px dashed darkblue;
        padding: 20px;
    }

    .news, .info, .accomodations, .img, .ablauf {
        margin-top: 30px;
    }

    .ablauf {
        padding-bottom: 25px;
    }

    .heading {
        font-size: 22px;
    }

    .App {
        font-size: 18px;
    }

    .bullet {
        margin-top: 20px;
        width: 300px;
    }

    .arrow {
        font-weight: bolder;
        margin-right: 5px;
        min-width: 20px;
    }
    
    .arrowInvisible {
        min-width: 20px;
        margin-right: 5px;
    }
    
    .bulletContainer {
        margin-bottom: 20px;
        transform: translateX(0px);
    }

    .coronaContainer {
        padding: 0 20px;
    }
}