.Locations {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.frame {
    width: 640px;
    height: 480px;
}

.description {
    width: 70%;
    text-align: center;
    margin-top: 15px;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1600px) {
    .frame {
        width: 500px;
        height: 350px;
    }
}


@media (max-width: 1150px) {
    .frame {
        width: 400px;
        height: 300px;
    }
}

@media (max-width: 1024px) {
    .Locations {
        flex-direction: column;
    }

    .frame {
        width: 640px;
        height: 480px;
    }

    .description {
        margin-bottom: 30px;
        width: 100%;
    }
}

@media (max-width: 750px) {
    .frame {
        width: 400px;
        height: 300px;
    }
}

@media (max-width: 500px) {
    .frame {
        width: 300px;
        height: 300px;
    }
}