.countdown {
    text-align: center;
    font-size: 38px;
    margin-top: 100px;
    font-family: Arial, Helvetica, sans-serif;
}

.number {
    margin: 10px;
    color: darkblue;
}

@media (max-width: 900px) {
    .countdown {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 1024px) {
    .countdown {
        margin-top: 50px;
        font-size: 28px;
    }
}

@media (max-width: 600px) {
    .countdown {
        margin-top: 30px;
        font-size: 20px;
    }
}